import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import {IconItem} from './IconItem'
import {TabPanel} from '../../components'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    props.handleClose()
  };

  const handleClick = (icon) => {
    props.handleIconChange(icon)
  }

  const listBrandIcons = () => {

    const list = Object.keys(fab).map(icon => {
      return [fab[icon].prefix, fab[icon].iconName]
    })

    return list.map(icon => {
      return <IconItem icon={icon} handleClick={handleClick} key={`icon-${icon[1]}`} />
    })

  }

  const listNormalIcons = () => {

    const list = Object.keys(fas).map(icon => {
      return [fas[icon].prefix, fas[icon].iconName]
    })

    return list.map(icon => {
      return <IconItem icon={icon} handleClick={handleClick} key={`icon-${icon[1]}`} />
    })

  }

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Choose an Icon
            </Typography>
            <Tabs value={value} onChange={handleChange} aria-label="Switch icons">
              <Tab label="Normal" {...a11yProps(0)} />
              <Tab label="Brands" {...a11yProps(1)} />
            </Tabs>
          </Toolbar>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2} style={{padding: 10}}>
            {listNormalIcons()}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2} style={{padding: 10}}>
            {listBrandIcons()}
          </Grid>
        </TabPanel>
      </Dialog>
    </div>
  );
}