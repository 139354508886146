import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import AuthUserContext from "../../components/Session/context";
import { Grid } from "@material-ui/core";

function LevelList(props) {
  const cell = {
    padding: "10px"
  };
  var { levels, loading } = props;

  //   Sort ascending
  levels.sort((a, b) => {
    if (a.from > b.from) {
      return 1;
    } else if (a.from < b.from) {
      return -1;
    } else {
      return 0;
    }
  });
  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <Grid item md={12} lg={6}>
          {loading && (
            <Grid item>
              <span>Loading...</span>
            </Grid>
          )}
          {!loading && (
            <Table aria-label="Levels">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>XP Required</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {levels.map(lvl => (
                  <TableRow key={lvl.id}>
                    <TableCell padding="checkbox" style={cell}>
                      <Avatar>
                        <LabelImportantIcon />
                      </Avatar>
                    </TableCell>
                    <TableCell>{lvl.name}</TableCell>
                    <TableCell>{lvl.from}</TableCell>
                    <TableCell>
                      {authUser.level === lvl.name && (
                        <Chip
                          avatar={<Avatar src={authUser.photoURL} />}
                          color="primary"
                          label="You"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      )}
    </AuthUserContext.Consumer>
  );
}

export default LevelList;
