import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from "../../components/Firebase";
import AuthUserContext from "../../components/Session/context";
import { NewAchievement } from "../../components/Achievements";
import Typography from '@material-ui/core/Typography';
import {Card} from '../../components'
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Loader} from '../../components'

// import { withAuthorization } from '../Session';
import * as ROLES from "../../constants/roles";

import {
  grey,
} from "@material-ui/core/colors";

const styles = theme => ({
  textCenter: {
    textAlign: "center"
  },
  mainIcon: {
    paddingTop: 50,
    color: grey[500]
  }
});

class AchievementsPage2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      achievements: [],
      newAchievement: {
        name: null,
        xp: 10
      },
      errors: {
        name: "",
        xp: ""
      }
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .achievements()
      .onSnapshot(snapshot => {
        var achievements = [];
        snapshot.docs.forEach(doc => {
          achievements.push({
            ...doc.data(),
            id: doc.id
          });
        });
        this.setState({
          achievements: achievements,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  cardList = () => {
    const {achievements, loading} = this.state
    const {classes} = this.props
    if (achievements.length === 0 && !loading) {
      return (
        <Grid container spacing={1} className={classes.mainIcon}>
          <Grid item xs={12} className={classes.textCenter}>
            <Typography variant="h2">
              <FontAwesomeIcon icon="sad-tear" />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.textCenter}>
            <Typography variant="subtitle1">You haven't entered any achievements yet</Typography>
          </Grid>
        </Grid>
      )
    } else {
      return achievements.map(achievement => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={`card-grid-${achievement.id}`}>
          <Card achievement={achievement} />
        </Grid>
      ))
    }
  }

  render() {
    const { loading } = this.state;

    return (
      <div style={{flex: 1}}>
        <div xs={12} style={{flex: 1, justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'}}>
          <Typography variant="h2" style={{paddingBottom: 15}}>Achievements</Typography>
          {/* <p style={{display: 'inline', float: 'right'}}>Space for Search</p> */}
        </div>
        <Grid container spacing={2}>
          {loading && <Loader />}
          {this.cardList()}
        </Grid>
        <AuthUserContext.Consumer>
          {authUser =>
            !!authUser.roles[ROLES.ADMIN] && (
              <NewAchievement />
            )
          }
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

export default withFirebase(withStyles(styles)(AchievementsPage2));
