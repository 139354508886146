import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withSnackbar } from 'notistack';

import { withFirebase } from "../Firebase";
import ActivityButton from '../ActivityButton';

function ConfirmationDialog(props) {
  const {open, handleClose, achievement} = props

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const handleSubmit = () => {
    setLoading(true)
    return props.firebase
      .achievements()
      .doc(achievement.id)
      .delete()
      .then(() => {
        console.info("Valid Delete");
        setLoading(false)
        setSuccess(true)
        handleClose()
        props.enqueueSnackbar('Achievement Deleted', {variant: 'success'})
      })
      .catch((error) => {
        console.log('error: ', error)
        setLoading(false)
        props.enqueueSnackbar('Please try again later', {variant: 'error'})
      })
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete dis?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Delete, {achievement.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <ActivityButton
            buttonName="Yes"
            onClick={handleSubmit}
            loading={loading}
            success={success} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withSnackbar(withFirebase(ConfirmationDialog))