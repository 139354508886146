import React, {useState} from 'react';
import { withSnackbar } from 'notistack';
import { withFirebase } from "../Firebase";

import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ChooseIcon} from '../../components/Achievements'
import colors from '../../constants/iconClasses.json'

import ActivityButton from '../ActivityButton';

const initialState = {
  errors: {
    name: "",
    XP: ""
  },
  loading: false,
  success: false,
  openIconDialog: false
};

function EditAchievement(props) {
  const [
    { errors, loading, success, openIconDialog },
    setState
  ] = useState(initialState);
  const [name, setName] = useState(props.achievement.name)
  const [XP, setXP] = useState(props.achievement.xp)
  const [description, setDesc] = useState(props.achievement.description)
  let [icon, setIcon] = useState(props.achievement.icon)

  const clearState = () => {
    setState({ ...initialState });
    setName("")
    setXP(10)
    setIcon("")
  };

  const onChangeName = event => {
    const { value } = event.target;
    setName(value)
    let tempErrors = errors
    tempErrors.name = value.length < 5 ? "Must be at least 5 characters" : ""
    setState(prevState => ({...prevState, tempErrors, valid: validateForm() }));
  }

  const onChangeXP = event => {
    const { value } = event.target;
    setXP(value)
    let tempErrors = errors
    tempErrors.XP = value < parseInt(0) || value > parseInt(1000) ? "The achievement's xp must be between 0 and 1000" : ""
    setState(prevState => ({...prevState, tempErrors, valid: validateForm() }))
  }

  const handleIconChange = (icon) => {
    setIcon(icon)
    handleCloseDialog()
  }

  const validateForm = () => {
    return (
      !errors.name.length > 0 && !errors.XP.length > 0
      && name !== undefined && description !== undefined
    )
  }

  const handleClickOpenDialog = () => {
    setState(prevState => ({ ...prevState, openIconDialog: true }))
  };

  const handleCloseDialog = () => {
    setState(prevState => ({ ...prevState, openIconDialog: false }))
  };

  const handleSubmit = event => {
    setState(prevState => ({ ...prevState, loading: true }))
    if (validateForm()) {
      const oldAchievement = props.firebase.achievements().doc(props.achievement.id)
      return oldAchievement.update({
        name, xp: XP, description, icon
      })
      .then(() => {
        console.info("Valid Form");
        setState(prevState => ({ ...prevState, loading: false, success: true }))
        setTimeout(() => {
          props.handleClose()
          props.enqueueSnackbar('Edit saved', {variant: 'success'})
          clearState()
        }, 1000);
      })
      .catch((error) => {
        console.log('error: ', error)
        setState(prevState => ({ ...prevState, loading: false }))
        props.enqueueSnackbar('Please try again later', {variant: 'error'})
      })
    } else {
      console.error("Invalid Form");
      setState(prevState => ({ ...prevState, loading: false }))
      props.enqueueSnackbar('Invalid Form', {variant: 'error'})
    }
  }

  if (!icon) {
    icon = ["fas", "question"]
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit achievement</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Some description about achievements and tings
          </DialogContentText>
          <TextField
            required
            autoFocus
            error={!!errors.name}
            margin="dense"
            id="name"
            label={"Achievement Name"}
            type="text"
            onChange={onChangeName}
            value={name}
            fullWidth
          />
          <TextField
            required
            margin="dense"
            id="desc"
            error={false}
            label="Achievement Description"
            type="text"
            onChange={(e) => setDesc(e.target.value)}
            value={description}
            fullWidth
          />
          <TextField
            required
            margin="dense"
            id="XP"
            error={!!errors.XP}
            label="Achievement XP"
            type="number"
            onChange={onChangeXP}
            value={XP}
          />
          <div style={{display: 'flex', flex: 1, marginTop: 15}}>
            <div style={{display: 'flex', width: '100%', flexDirection: 'row'}}>
              <ListItemIcon>
                <Avatar aria-label="recipe" style={{backgroundColor: 'white'}}>
                  <FontAwesomeIcon icon={icon} color={colors[`fa-${icon[1]}`]} />
                </Avatar>
              </ListItemIcon>
              <Button onClick={handleClickOpenDialog} variant="contained" style={{alignSelf: 'center'}}>
                {icon[1] === 'question' ? 'Choose an Icon' : 'Edit Icon'}
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <ActivityButton
            buttonName="Save"
            onClick={handleSubmit}
            loading={loading}
            success={success} />
        </DialogActions>
      </Dialog>
      <ChooseIcon open={openIconDialog} handleClose={handleCloseDialog} handleIconChange={handleIconChange}/>
    </div>
  );
}

export default withSnackbar(withFirebase(EditAchievement))
