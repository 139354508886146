import React from 'react'

import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import colors from '../../constants/iconClasses.json'

export const IconItem = (props) => {
  const {icon} = props
  const name = icon[1]
  const [clicked, setClicked] = React.useState(false)

  const handleClick = () => {
    setClicked(!clicked)
    if (!clicked) props.handleClick(icon)
  }

  return (
    <Grid item xs={5} sm={4} md={3} lg={2} xl={1} key={`icon-${name}`} onClick={handleClick}>
      <Paper style={{display: 'flex', flexDirection: 'column', padding: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: clicked ? 'green' : 'transparent'}}>
        <Avatar style={{backgroundColor: 'white', width: 75, height: 75}}>
          <FontAwesomeIcon icon={icon} size="2x" color={colors[`fa-${name}`]}/>
        </Avatar>
        <p style={{textTransform: 'capitalize', textAlign: 'center', padding: 0, margin: 0, marginTop: 10}}>{name}</p>
      </Paper>
    </Grid>
  )
}

