import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AuthUserContext from "../../components/Session/context";
import * as ROLES from "../../constants/roles";
import {EditAchievement} from '../Achievements'
import {DeleteDialog} from '../'

import colors from '../../constants/iconClasses.json'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

// interface Props {
//   achievement: Achievement
// }

// interface Achievement {
//   description: string
//   icon: string[]
//   length: number
//   name: string
//   xp: number
//   id: string
// }

function AchievementCard({achievement}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [raised, setRaised] = React.useState(false);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMouseOver = () => setRaised(true);
  const onMouseOut = () => setRaised(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleEditOpen = () => {
    setOpenEdit(true)
  };

  const handleEditClose = () => {
    setOpenEdit(false)
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  }

  const handleDeleteClose = () => {
    setOpenDelete(false);
  }

  const thing = (authUser) => {
    if (!!authUser.roles[ROLES.ADMIN]) {
      return (
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup='true'
          aria-label="settings"
          onClick={event => handleActionClick(event, authUser)}>
          <MoreVertIcon />
        </IconButton>
      )
    } else return null
  }

  const {name, xp} = achievement
  let {icon, description} = achievement

  if (!icon) {
    icon = ['fas', 'trophy']
  }

  if (!description) {
    description = 'Description pending...'
  }

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <Card className={classes.card} onMouseOver={onMouseOver} onMouseOut={onMouseOut} raised={raised}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" style={{backgroundColor: 'white'}}>
                <FontAwesomeIcon icon={icon} color={colors[`fa-${Array.isArray(icon) ? icon[1] : icon}`]} />
              </Avatar>
            }
            action={thing(authUser)}
            title={name}
            // subheader="September 14, 2016"
          />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleEditOpen()
                handleClose()
              }
            }>
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDeleteOpen()
                handleClose()
              }}>
                Delete
            </MenuItem>
          </Menu>
          <CardContent style={{height: expanded ? "auto" : 50, overflow: 'hidden', textOverflow: 'ellipsis'}}>
            {!expanded && <Typography variant="body2" color="textSecondary" component="p" noWrap>
              {description}
            </Typography>}
            <Collapse
              in={expanded}
              timeout="auto"
              unmountOnExit
              collapsedHeight="50px">
              <Typography variant="body2" color="textSecondary" component="p" >
                {description}
              </Typography>
            </Collapse>
          </CardContent>
          <CardActions disableSpacing>
            <Avatar style={{marginLeft: 8, marginRight: 10, backgroundColor: 'white', fontSize: '1.0rem'}}>
              {xp}
            </Avatar>
            {/* <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton> */}
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              disabled={description === 'Description pending...'}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <DeleteDialog open={openDelete} handleClose={handleDeleteClose} achievement={achievement} />
          <EditAchievement open={openEdit} handleClose={handleEditClose} achievement={achievement} />
        </Card>
      )}
    </AuthUserContext.Consumer>
  );
}

export default AchievementCard
