import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  amber,
  blueGrey,
  green,
  grey,
  deepOrange
} from "@material-ui/core/colors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Loader } from "../../components";
import { withFirebase } from "../Firebase";

const styles = theme => ({
  root: {
    width: "100%"
  },
  textRight: {
    textAlign: "right"
  },
  textCenter: {
    textAlign: "center"
  },
  textItalic: {
    fontStyle: "italic"
  },
  gold: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.getContrastText(amber[600]),
    backgroundColor: amber[600]
  },
  silver: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.getContrastText(blueGrey[100]),
    backgroundColor: blueGrey[100]
  },
  bronze: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.getContrastText(deepOrange[900]),
    backgroundColor: deepOrange[900]
  },
  heading: {
    paddingTop: 10,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular
  },
  achieved: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500])
  },
  starred: {
    marginTop: 10,
    color: amber[600]
  },
  notStarred: {
    marginTop: 10,
    color: grey[300]
  },
  progress: {
    textAlign: "center",
    height: 10,
    marginTop: 16
  },
  mainIcon: {
    paddingTop: 25,
    color: grey[500]
  }
});

class SpecialitiesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      specialities: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    var achievements = {};
    var userAchievements = {};
    var userSpecialities = {};
    this.props.firebase
      .achievements()
      .get()
      .then(ach => {
        ach.docs.forEach(doc => {
          achievements[doc.id] = {
            id: doc.id,
            ...doc.data()
          };
        });
      })
      .then(() =>
        this.props.firebase.userSpecialities(this.props.authUser.uid).get()
      )
      .then(ua => {
        ua.docs.forEach(doc => {
          userSpecialities[doc.id] = {
            ...doc.data(),
            id: doc.id
          };
        });
      })
      .then(() =>
        this.props.firebase.userAchievements(this.props.authUser.uid).get()
      )
      .then(ua => {
        ua.docs.forEach(doc => {
          userAchievements[doc.id] = {
            ...doc.data(),
            id: doc.id
          };
        });
      })
      .then(() =>
        this.props.firebase
          .specialities()
          .orderBy("name")
          .get()
      )
      .then(snapshot => {
        var specialities = [];
        snapshot.docs.forEach(doc => {
          var totalAchievementsRequired = 0;
          var totalAchievements = 0;
          let data = doc.data();

          data.requirements.forEach(r => {
            totalAchievementsRequired += r.required;
            for (let i = 0; i < r.achievements.length; i++) {
              r.achievements[i] = achievements[r.achievements[i]];
              if (!!userAchievements[r.achievements[i].id]) {
                r.achievements[i].completed = true;
                if (totalAchievements < r.required) {
                  totalAchievements++;
                }
              }
            }
            r.achievements.sort((a, b) => (a.name > b.name ? 1 : -1));
          });
          specialities.push({
            progress: !!userSpecialities[doc.id]
              ? 100
              : Math.ceil(
                  (totalAchievements * 100) / totalAchievementsRequired
                ),
            ...data,
            id: doc.id
          });
        });
        for (let i = 0; i < specialities.length; i++) {
          for (let j = 0; j < specialities[i].prerequisites.length; j++) {
            let prereq = specialities.filter(
              f => f.id === specialities[i].prerequisites[j]
            );
            if (prereq.length > 0) {
              specialities[i].prerequisites[j] = prereq[0];
            }
          }
          if (
            specialities[i].prerequisites.length !==
            specialities[i].prerequisites.filter(p => !!userSpecialities[p.id])
              .length
          ) {
            specialities[i].progress = -1;
          }
        }
        console.log(userSpecialities);
        this.setState({ loading: false, specialities: specialities });
      });
  }

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;
    const { specialities, loading } = this.state;

    if (loading) {
      return <Loader />;
    } else if (specialities.length === 0) {
      return (
        <Grid container spacing={1} className={classes.mainIcon}>
          <Grid item xs={12} className={classes.textCenter}>
            <Typography variant="h2">
              <FontAwesomeIcon icon="sad-tear" />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.textCenter}>
            <Typography variant="subtitle1">No speicalities found</Typography>
          </Grid>
        </Grid>
      );
    } else {
      return (
        specialities
          // .filter(s => {
          //   if (starFilter && !s.starred) {
          //     return false;
          //   } else {
          //     return true;
          //   }
          // })
          .map(speciality => (
            <ExpansionPanel key={speciality.id}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container spacing={3}>
                  <Grid item xs={2} sm={1}>
                    <Avatar className={classes[speciality.type]}>
                      <FontAwesomeIcon icon={speciality.icon} />
                    </Avatar>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.heading}>
                      {speciality.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={3}>
                    {speciality.progress >= 0 && (
                      <LinearProgress
                        color="primary"
                        variant="determinate"
                        className={classes.progress}
                        value={speciality.progress}
                      />
                    )}
                  </Grid>
                  <Hidden only={"xs"}>
                    <Grid item sm={1} className={classes.textRight}>
                      <Typography className={classes.heading}>
                        {speciality.progress >= 0 && (
                          <span> {speciality.progress}%</span>
                        )}
                        {speciality.progress === -1 && <span>N/A</span>}
                      </Typography>
                    </Grid>
                  </Hidden>
                  <Grid item sm={1} className={classes.textRight}>
                    <Typography className={classes.heading}>
                      <span>{speciality.xp} xp</span>
                    </Typography>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Description</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography variant="subtitle1">
                      {speciality.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.textCenter}>
                    <Typography variant="h2">{speciality.xp} xp</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  {speciality.prerequisites.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        Prerequisite specialities
                      </Typography>
                    </Grid>
                  )}
                  {speciality.prerequisites.map((prereq, index) => (
                    <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                      <Card
                        className={
                          prereq.progress === 100 ? classes.achieved : ""
                        }
                      >
                        <CardHeader
                          avatar={
                            <Avatar className={prereq.level}>
                              <FontAwesomeIcon icon={prereq.icon} />
                            </Avatar>
                          }
                          action={
                            prereq.progress === 100 && (
                              <FontAwesomeIcon icon={"check"} />
                            )
                          }
                          title={prereq.name}
                          subheader={prereq.xp + " xp"}
                        />
                      </Card>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Typography variant="h5">Required achievements</Typography>
                  </Grid>
                  {speciality.requirements.map((req, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          {req.required === req.achievements.length
                            ? "All"
                            : req.required}{" "}
                          of the following...
                        </Typography>
                      </Grid>
                      {req.achievements.map(achievement => (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={4}
                          xl={3}
                          key={achievement.id}
                        >
                          <Card
                            className={
                              achievement.completed ? classes.achieved : ""
                            }
                          >
                            <CardHeader
                              avatar={
                                <Avatar className={achievement.level}>
                                  <FontAwesomeIcon icon={achievement.icon} />
                                </Avatar>
                              }
                              action={
                                achievement.completed && (
                                  <FontAwesomeIcon icon="check" />
                                )
                              }
                              title={achievement.name}
                              subheader={achievement.xp + " xp"}
                            />
                          </Card>
                        </Grid>
                      ))}
                    </React.Fragment>
                  ))}
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))
      );
    }
  }
}
export default withFirebase(withStyles(styles)(SpecialitiesList));
