import React, {useState} from 'react';
import { withSnackbar } from 'notistack';
import { withFirebase } from "../Firebase";

import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ChooseIcon} from '../../components/Achievements'
import colors from '../../constants/iconClasses.json'

import ActivityButton from '../ActivityButton';


const fab = {
  margin: "0px",
  top: "auto",
  right: "20px",
  bottom: "20px",
  left: "auto",
  position: "fixed",
}

const initialState = {
  open: false,
  errors: {
    name: "",
    XP: ""
  },
  valid: false,
  loading: false,
  success: false,
  openIconDialog: false
};

function NewAchievement(props) {
  const [
    { open, valid, errors, loading, success, openIconDialog },
    setState
  ] = useState(initialState);
  const [name, setName] = useState("")
  const [XP, setXP] = useState(10)
  const [description, setDesc] = useState("")
  let [icon, setIcon] = useState("")

  const clearState = () => {
    setState({ ...initialState });
    setName("")
    setXP(10)
    setIcon("")
  };

  const onChangeName = event => {
    const { value } = event.target;
    setName(value)
    let tempErrors = errors
    tempErrors.name = value.length < 5 ? "Must be at least 5 characters" : ""
    setState(prevState => ({...prevState, tempErrors, valid: validateForm() }));
  }

  const onChangeXP = event => {
    const { value } = event.target;
    setXP(value)
    let tempErrors = errors
    tempErrors.XP = value < parseInt(0) || value > parseInt(1000) ? "The achievement's xp must be between 0 and 1000" : ""
    setState(prevState => ({...prevState, tempErrors, valid: validateForm() }))
  }

  const handleIconChange = (icon) => {
    setIcon(icon)
    handleCloseDialog()
  }

  const validateForm = () => {
    return (
      !errors.name.length > 0 && !errors.XP.length > 0
    )
  }

  const handleClickOpenDialog = () => {
    setState(prevState => ({ ...prevState, openIconDialog: true }))
  };

  const handleCloseDialog = () => {
    setState(prevState => ({ ...prevState, openIconDialog: false }))
  };

  const handleClickOpen = () => {
    setState(prevState => ({ ...prevState, open: true }))
  };

  const handleClose = () => {
    setState(prevState => ({ ...prevState, open: false }))
  };

  const handleSubmit = event => {
    setState(prevState => ({ ...prevState, loading: true }))
    if (valid) {
      props.firebase
        .achievements()
        .add({ name, xp: XP, description, icon })
        .then(() => {
          console.info("Valid Form");
          clearState()
          setState(prevState => ({ ...prevState, loading: false, success: true }))
          props.enqueueSnackbar('Achievement saved', {variant: 'success'})
        })
        .catch((error) => {
          console.log('error: ', error)
          setState(prevState => ({ ...prevState, loading: false }))
          props.enqueueSnackbar('Please try again later', {variant: 'error'})
        })
    } else {
      console.error("Invalid Form");
      setState(prevState => ({ ...prevState, loading: false }))
      props.enqueueSnackbar('Invalid Form', {variant: 'error'})
    }
  }

  if (!icon) {
    icon = ["fas", "question"]
  }

  return (
    <div>
      <Fab color="primary" aria-label="add" style={fab} onClick={handleClickOpen}>
        <AddIcon />
      </Fab>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add an achievement</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Some description about achievements and tings
          </DialogContentText>
          <TextField
            autoFocus
            error={!!errors.name}
            margin="dense"
            id="name"
            label={"Achievement Name"}
            type="text"
            onChange={onChangeName}
            value={name}
            fullWidth
          />
          <TextField
            margin="dense"
            id="desc"
            error={false}
            label="Achievement Description"
            type="text"
            onChange={(e) => setDesc(e.target.value)}
            value={description}
            fullWidth
          />
          <TextField
            margin="dense"
            id="XP"
            error={!!errors.XP}
            label="Achievement XP"
            type="number"
            onChange={onChangeXP}
            value={XP}
          />
          <div style={{display: 'flex', flex: 1, marginTop: 15}}>
            <div style={{display: 'flex', width: '100%', flexDirection: 'row'}}>
              <ListItemIcon>
                <Avatar aria-label="recipe" style={{backgroundColor: 'white'}}>
                  <FontAwesomeIcon icon={icon} color={colors[`fa-${icon[1]}`]} />
                </Avatar>
              </ListItemIcon>
              <Button onClick={handleClickOpenDialog} variant="contained" style={{alignSelf: 'center'}}>
                Choose an Icon
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <ActivityButton
            buttonName="Create"
            onClick={handleSubmit}
            loading={loading}
            success={success} />
        </DialogActions>
      </Dialog>
      <ChooseIcon open={openIconDialog} handleClose={handleCloseDialog} handleIconChange={handleIconChange}/>
    </div>
  );
}

export default withSnackbar(withFirebase(NewAchievement))
